import React, { useEffect, useState } from 'react';

import {
  Form,
  Input,
  Button,
  Select,
  Tooltip,
  Badge,
  Typography,
  Divider,
  InputNumber,
  Space,
  Radio,
  Dropdown,
  Menu,
  Spin,
  Modal,
  Empty,
  Tag,
  Tabs,
} from 'antd';
import { FormProps } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import {
  HedrDetailsFragment,
  useSearchPatientsByHedrDataLazyQuery,
  SearchHedRsDocument,
  useUpdateHedrMutation,
  useGetNetworkByNumberLazyQuery,
  useGetPracticeDetailsLazyQuery,
} from '../../graphql/generated';
import dayjs from 'dayjs';
import { DatePicker } from '../';
import { DownOutlined, FileOutlined, HistoryOutlined } from '@ant-design/icons';
import NetworkTag from '../networks/NetworkTag';
import { getOperationAST } from 'graphql';
import { WebOutlined } from '../MabelIcons';
import { ShortURLResponse } from '../alignments/AlignmentsTable';
import PracticeTag from '../practices/PracticeTag';
import { HEDRAuditTimeline } from '../auditLogs';

const LANGUAGES = [
  'Abkhazian',
  'Afar',
  'Afrikaans',
  'Akan',
  'Albanian',
  'Amharic',
  'Arabic',
  'Aragonese',
  'Armenian',
  'Assamese',
  'Avaric',
  'Avestan',
  'Aymara',
  'Azerbaijani',
  'Bambara',
  'Bashkir',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bislama',
  'Bosnian',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Catalan, Valencian',
  'Chamorro',
  'Chechen',
  'Chichewa, Chewa, Nyanja',
  'Chinese',
  'Church Slavic, Old Slavonic',
  'Chuvash',
  'Cornish',
  'Corsican',
  'Cree',
  'Croatian',
  'Czech',
  'Danish',
  'Divehi, Dhivehi, Maldivian',
  'Dutch, Flemish',
  'Dzongkha',
  'English',
  'Esperanto',
  'Estonian',
  'Ewe',
  'Faroese',
  'Fijian',
  'Finnish',
  'French',
  'Western Frisian',
  'Fulah',
  'Gaelic, Scottish Gaelic',
  'Galician',
  'Ganda',
  'Georgian',
  'German',
  'Greek, Modern',
  'Kalaallisut, Greenlandic',
  'Guarani',
  'Gujarati',
  'Haitian, Haitian Creole',
  'Hausa',
  'Hebrew',
  'Herero',
  'Hindi',
  'Hiri Motu',
  'Hungarian',
  'Icelandic',
  'Ido',
  'Igbo',
  'Indonesian',
  'Interlingua',
  'Interlingue, Occidental',
  'Inuktitut',
  'Inupiaq',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kannada',
  'Kanuri',
  'Kashmiri',
  'Kazakh',
  'Central Khmer',
  'Kikuyu, Gikuyu',
  'Kinyarwanda',
  'Kirghiz, Kyrgyz',
  'Komi',
  'Kongo',
  'Korean',
  'Kuanyama, Kwanyama',
  'Kurdish',
  'Lao',
  'Latin',
  'Latvian',
  'Limburgan, Limburger, Limburgish',
  'Lingala',
  'Lithuanian',
  'Luba-Katanga',
  'Luxembourgish, Letzeburgesch',
  'Macedonian',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Manx',
  'Maori',
  'Marathi',
  'Marshallese',
  'Mongolian',
  'Nauru',
  'Navajo, Navaho',
  'North Ndebele',
  'South Ndebele',
  'Ndonga',
  'Nepali',
  'Norwegian',
  'Norwegian Bokmål',
  'Norwegian Nynorsk',
  'Sichuan Yi, Nuosu',
  'Occitan',
  'Ojibwa',
  'Oriya',
  'Oromo',
  'Ossetian, Ossetic',
  'Pali',
  'Pashto, Pushto',
  'Persian',
  'Polish',
  'Portuguese',
  'Punjabi, Panjabi',
  'Quechua',
  'Romanian, Moldavian, Moldovan',
  'Romansh',
  'Rundi',
  'Russian',
  'Northern Sami',
  'Samoan',
  'Sango',
  'Sanskrit',
  'Sardinian',
  'Serbian',
  'Shona',
  'Sindhi',
  'Sinhala, Sinhalese',
  'Slovak',
  'Slovenian',
  'Somali',
  'Southern Sotho',
  'Spanish, Castilian',
  'Sundanese',
  'Swahili',
  'Swati',
  'Swedish',
  'Tagalog',
  'Tahitian',
  'Tajik',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tigrinya',
  'Tonga (Tonga Islands)',
  'Tsonga',
  'Tswana',
  'Turkish',
  'Turkmen',
  'Twi',
  'Uighur, Uyghur',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Venda',
  'Vietnamese',
  'Volapük',
  'Walloon',
  'Welsh',
  'Wolof',
  'Xhosa',
  'Yiddish',
  'Yoruba',
  'Zhuang, Chuang',
  'Zulu',
];

const DETAILED_ETHNICITIES = [
  'American Indian',
  'Alaska Native',
  'Asian Indian',
  'Bangladeshi',
  'Bhutanese',
  'Burmese',
  'Cambodian',
  'Chinese',
  'Taiwanese',
  'Filipino',
  'Hmong',
  'Indonesian',
  'Japanese',
  'Korean',
  'Laotian',
  'Malaysian',
  'Okinawan',
  'Pakistani',
  'Sri Lankan',
  'Thai',
  'Vietnamese',
  'Iwo Jiman',
  'Maldivian',
  'Nepalese',
  'Singaporean',
  'Madagascar',
  'Black',
  'African American',
  'African',
  'Bahamian',
  'Barbadian',
  'Dominican',
  'Dominica Islander',
  'Haitian',
  'Jamaican',
  'Tobagoan',
  'Trinidadian',
  'West Indian',
  'Polynesian',
  'Micronesian',
  'Melanesian',
  'Other Pacific Islander',
  'European',
  'Middle Eastern or North African',
  'Arab',
];

interface HEDRFormProps extends FormProps {
  initialHEDR: HedrDetailsFragment;
  parsedQRCodes?: (ShortURLResponse | null)[];
}

const HEDRForm: React.FC<HEDRFormProps> = ({
  initialHEDR,
  parsedQRCodes,
  ...rest
}) => {
  const [haveValuesChanged, setHaveValuesChanged] = useState(false);
  const [formInternal] = Form.useForm();
  const [updateHedrForm, updateHedrFormResults] = useUpdateHedrMutation();

  const [selectedFormTab, setSelectedFormTab] = useState('');
  const mapTemplateVersionToTab = (template_version: string) => {
    return template_version.startsWith('HEDR-DEMO')
      ? 'HEDR-DEMO'
      : template_version.startsWith('HEDR-SDOH-NC')
      ? 'HEDR-SDOH-NC'
      : template_version.startsWith('HEDR-SDOH-AHC')
      ? 'HEDR-SDOH-AHC'
      : template_version.startsWith('HEDR-SDOH-PRA')
      ? 'HEDR-SDOH-PRA'
      : '';
  };

  const [
    getNetworkByNumber,
    getNetworkByNumberResults,
  ] = useGetNetworkByNumberLazyQuery({ fetchPolicy: 'network-only' });

  const [
    getPracticeDetails,
    getPracticeDetailsResults,
  ] = useGetPracticeDetailsLazyQuery({ fetchPolicy: 'network-only' });

  const [
    searchPatientsByHEDRData,
    searchPatientsByHEDRDataResults,
  ] = useSearchPatientsByHedrDataLazyQuery({ fetchPolicy: 'network-only' });

  const uniquePatientsByHEDRDataResults =
    searchPatientsByHEDRDataResults.data?.search_patients_by_hedr_data || [];

  const recordToFormVals = ({
    date_assessment_complete,
    birth_date,
    ...rest
  }: HedrDetailsFragment) => {
    return {
      date_assessment_complete: dayjs(date_assessment_complete).isValid()
        ? dayjs(date_assessment_complete)
        : null,
      birth_date: dayjs(birth_date).isValid() ? dayjs(birth_date) : null,
      ...rest,
    };
  };

  useEffect(() => {
    // get network number from QR code
    const parsed_nn = parseInt(
      parsedQRCodes?.find((q) => q?.query_params.network_number)?.query_params
        .network_number || ''
    );
    const network_from_qr_code = isNaN(parsed_nn) ? undefined : parsed_nn;
    const network_number =
      initialHEDR.network?.network_number || network_from_qr_code;

    if (!initialHEDR.network_number && network_number) {
      formInternal.setFieldsValue({ network_number: network_number });
      setHaveValuesChanged(true);
    }

    getNetworkByNumber({
      variables: { network_number: network_number ?? -1 },
    });
    if (
      initialHEDR.beneficiary_name ||
      initialHEDR.mbi ||
      initialHEDR.birth_date ||
      initialHEDR.letter_id ||
      initialHEDR.letter_id_prefix
    ) {
      searchPatientsByHEDRData({
        variables: {
          network_number: network_number ?? -1,
          mbi: initialHEDR.mbi || '',
          patient_name: initialHEDR.beneficiary_name || '',
          letter_id_prefix: initialHEDR.letter_id
            ? initialHEDR.letter_id.toLowerCase()
            : initialHEDR.letter_id_prefix
            ? initialHEDR.letter_id_prefix.length < 3
              ? ''
              : `${initialHEDR.letter_id_prefix.toLowerCase()}%`
            : null,
          birth_date: initialHEDR.birth_date || null,
        },
      });
    }

    // set selected tab based on template version
    setSelectedFormTab(
      mapTemplateVersionToTab(initialHEDR.template_version || '')
    );
  }, [
    initialHEDR,
    parsedQRCodes,
    formInternal,
    searchPatientsByHEDRData,
    getNetworkByNumber,
  ]);

  useEffect(() => {
    const practice_from_qr_code = parsedQRCodes?.find(
      (q) => q?.query_params.practice_id
    )?.query_params.practice_id;
    const practice_id = initialHEDR.form_practice_id || practice_from_qr_code;
    if (!initialHEDR.form_practice_id && practice_id) {
      formInternal.setFieldsValue({ form_practice_id: practice_id });
      setHaveValuesChanged(true);
    }

    if (practice_id) {
      getPracticeDetails({
        variables: {
          practice_id,
        },
      });
    }
  }, [initialHEDR, parsedQRCodes, formInternal, getPracticeDetails]);

  // Takes the form vals and puts them into right format for writing
  const formValsToRecordChanges = (formValues: Store) => {
    const { date_assessment_complete, birth_date, ...rest } = formValues;
    const new_obj = {
      date_assessment_complete:
        date_assessment_complete &&
        (date_assessment_complete as dayjs.Dayjs).format('YYYY-MM-DD'),
      birth_date:
        birth_date && (birth_date as dayjs.Dayjs).format('YYYY-MM-DD'),
      ...rest,
    } as any;
    // replace undefined or empty string with null in new_obj
    Object.keys(new_obj).forEach((key) => {
      if (new_obj[key] === undefined || new_obj[key] === '')
        new_obj[key] = null;
    });
    const changes = initialHEDR
      ? Object.fromEntries(
          Object.entries(new_obj).filter(
            ([k, v]) => (initialHEDR as any)[k] !== v
          )
        )
      : new_obj;
    return changes;
  };

  const valuesHaveChanged = (formValues: Store) => {
    return Object.keys(formValsToRecordChanges(formValues)).length > 0;
  };

  const handleUpdate = (values: any) => {
    const changes = formValsToRecordChanges(values);
    updateHedrForm({
      variables: {
        health_equity_report_id: initialHEDR.health_equity_report_id,
        changes: Object.keys(changes).length ? changes : undefined,
      },
      refetchQueries: [getOperationAST(SearchHedRsDocument)?.name?.value || ''],
    });
    if (setHaveValuesChanged) setHaveValuesChanged(false);
  };

  const [isHEDRAuditModalVisible, setIsHEDRAuditModalVisible] = useState(false);

  return (
    <Form
      {...rest}
      form={formInternal}
      size="small"
      initialValues={recordToFormVals(initialHEDR)}
      onValuesChange={(changedValues, allValues) => {
        if (setHaveValuesChanged) {
          setHaveValuesChanged(valuesHaveChanged(allValues));
        }
        if (changedValues.hasOwnProperty('network_number')) {
          getNetworkByNumber({
            variables: { network_number: changedValues.network_number },
          });
        }
        if (
          changedValues.hasOwnProperty('network_number') ||
          changedValues.hasOwnProperty('mbi') ||
          changedValues.hasOwnProperty('beneficiary_name') ||
          changedValues.hasOwnProperty('letter_id_prefix') ||
          changedValues.hasOwnProperty('birth_date')
        ) {
          searchPatientsByHEDRData({
            variables: {
              network_number: allValues.network_number ?? -1,
              mbi: allValues.mbi || '',
              patient_name: allValues.beneficiary_name || '',
              letter_id_prefix: allValues.letter_id_prefix
                ? allValues.letter_id_prefix.length < 3
                  ? ''
                  : `${allValues.letter_id_prefix.toLowerCase()}%`
                : null,
              birth_date:
                (allValues.birth_date &&
                  (allValues.birth_date as dayjs.Dayjs).format('YYYY-MM-DD')) ||
                null,
            },
          });
        }
        if (changedValues.hasOwnProperty('template_version')) {
          setSelectedFormTab(
            mapTemplateVersionToTab(changedValues.template_version || '')
          );
        }
      }}
      onFinish={handleUpdate}
    >
      <Form.Item
        label="HEDR ID"
        labelCol={{ span: 5 }}
        style={{ marginBottom: 0 }}
      >
        <Space direction="horizontal">
          <Typography.Text copyable>
            {initialHEDR.health_equity_report_id}
          </Typography.Text>
          {initialHEDR.pages && (
            <Tag color={initialHEDR.pages.length > 1 ? 'red' : 'green'}>
              {initialHEDR.pages.length} page
              {initialHEDR.pages.length > 1 ? 's' : ''}
              {/* <Tag
                color={
                  initialHEDR.pages.reduce(
                    (sum: number, current: any) =>
                      sum +
                      (current?.barcodes?.filter(
                        (code: any) => code.kind === 'QRCode'
                      ).length || 0),
                    0
                  ) !== 1
                    ? 'red'
                    : 'green'
                }
                icon={<QrcodeOutlined />}
              >
                {initialHEDR.pages.reduce(
                  (sum: number, current: any) =>
                    sum +
                    (current?.barcodes?.filter(
                      (code: any) => code.kind === 'QRCode'
                    ).length || 0),
                  0
                )}{' '}
                QR Code
                {initialHEDR.pages.reduce(
                  (sum: number, current: any) =>
                    sum +
                    (current?.barcodes?.filter(
                      (code: any) => code.kind === 'QRCode'
                    ).length || 0),
                  0
                ) !== 1
                  ? 's'
                  : ''}
              </Tag> */}
            </Tag>
          )}
        </Space>
        <br />
        <small>
          <i>
            Created{' '}
            {dayjs(initialHEDR.created_at).format('YYYY-MM-DD HH:mm:ss')}{' '}
            <Tooltip title="View Changes">
              <Button
                type="link"
                icon={<HistoryOutlined />}
                onClick={() => setIsHEDRAuditModalVisible(true)}
              />
            </Tooltip>
            <Modal
              title={'HEDR Audit - ' + initialHEDR.health_equity_report_id}
              visible={isHEDRAuditModalVisible}
              width={600}
              footer={null}
              onCancel={() => setIsHEDRAuditModalVisible(false)}
              destroyOnClose
              maskClosable
            >
              <HEDRAuditTimeline
                health_equity_report_id={initialHEDR.health_equity_report_id}
              />
            </Modal>
          </i>
        </small>
      </Form.Item>
      <Form.Item label="Submission ID" labelCol={{ span: 5 }}>
        {initialHEDR.submission_source === 'EarthClassMail' ? (
          <Typography.Link
            href={encodeURI(
              `https://app.earthclassmail.com/inboxes/${
                process.env.REACT_APP_ECM_INBOX_ID
              }/pieces?contains=${initialHEDR.submission_id?.split('.')[0]}`
            )}
            target="_blank"
          >
            <img
              alt="EarthClassMail icon"
              src="https://www.earthclassmail.com/images/logo/ecm-no-text.svg"
              width={12}
              style={{ margin: 4 }}
            />{' '}
            {initialHEDR.submission_id}
          </Typography.Link>
        ) : initialHEDR.submission_source === 'StableMail' ? (
          <Typography.Link
            href={encodeURI(
              `https://dashboard.usestable.com/mailroom/${
                initialHEDR.submission_id?.split('.')[0]
              }`
            )}
            target="_blank"
          >
            <img
              alt="StableMail icon"
              src="https://assets-global.website-files.com/63af3bafea6dbc658ece0e8d/63c1d1f9b0f8b51af0163f87_stable-32.png"
              width={12}
              style={{ margin: 4 }}
            />{' '}
            {initialHEDR.submission_id}
          </Typography.Link>
        ) : initialHEDR.submission_source === 'Jotform' ? (
          <Typography.Text>
            <img
              alt="Jotform icon"
              src="https://www.jotform.com/resources/assets/svg/jotform-icon-transparent.svg"
              width={20}
            />{' '}
            {initialHEDR.submission_id}
          </Typography.Text>
        ) : initialHEDR.submission_source === 'DropboxUpload' ? (
          <>
            <img
              alt="DropboxUpload icon"
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Dropbox_Icon.svg"
              width={20}
            />{' '}
            {initialHEDR.submission_id}
          </>
        ) : initialHEDR.submission_source === 'WebUpload' ? (
          <>
            <WebOutlined style={{ width: 20 }} />
            {initialHEDR.submission_id}
          </>
        ) : initialHEDR.submission_source === 'FileImport' ? (
          <>
            <FileOutlined style={{ width: 20 }} />
            {initialHEDR.submission_id}
          </>
        ) : (
          initialHEDR.submission_id
        )}
      </Form.Item>
      <Form.Item
        label="Letter ID Prefix"
        labelCol={{ span: 5 }}
        style={{ marginBottom: 0 }}
        dependencies={['letter_id_prefix']}
      >
        {({ getFieldValue }) => (
          <Space align="start">
            <Form.Item
              name="letter_id_prefix"
              style={{ display: 'inline-block', width: 120, marginBottom: 4 }}
              help={
                getFieldValue('letter_id_prefix') &&
                (getFieldValue('letter_id_prefix').length < 3
                  ? 'At least 3 chars requried'
                  : getFieldValue('letter_id_prefix').match(/[^0-9a-f]/i)
                  ? 'ID contains unexpected character'
                  : getFieldValue('letter_id_prefix').length > 8
                  ? 'Only 8 charaters expected'
                  : undefined)
              }
              validateStatus={
                getFieldValue('letter_id_prefix') &&
                (getFieldValue('letter_id_prefix').length < 3 ||
                getFieldValue('letter_id_prefix').match(/[^0-9a-f]/i)
                  ? 'error'
                  : getFieldValue('letter_id_prefix').length > 8
                  ? 'warning'
                  : 'success')
              }
            >
              <Input allowClear autoComplete="off" />
            </Form.Item>
            {initialHEDR.letter_id && (
              <Form.Item
                name="letter_id"
                style={{ display: 'inline-block', width: 320, marginBottom: 4 }}
              >
                <Typography.Text type="secondary">
                  <small>{initialHEDR.letter_id}</small>
                </Typography.Text>
              </Form.Item>
            )}
          </Space>
        )}
      </Form.Item>
      <Form.Item
        labelCol={{ span: 5 }}
        label="Network Number"
        style={{ marginBottom: 4 }}
        validateStatus={
          getNetworkByNumberResults.loading
            ? 'validating'
            : getNetworkByNumberResults.data?.networks?.length === 0
            ? 'error'
            : 'success'
        }
        help={
          getNetworkByNumberResults.data?.networks.length === 0
            ? 'No matching network found'
            : null
        }
      >
        <Space>
          <Form.Item name="network_number" noStyle>
            <InputNumber keyboard={false} />
          </Form.Item>
          {getNetworkByNumberResults.data?.networks[0]?.network_id && (
            <NetworkTag network={getNetworkByNumberResults.data?.networks[0]} />
          )}
        </Space>
      </Form.Item>
      <Form.Item
        labelCol={{ span: 5 }}
        name="form_practice_id"
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.form_practice_id !== currentValues.form_practice_id
        }
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Practice ID"
        labelCol={{ span: 5 }}
        style={{ marginBottom: 4 }}
      >
        {getPracticeDetailsResults.loading ? (
          <Spin />
        ) : (
          getPracticeDetailsResults.data?.practices_by_pk && (
            <>
              <PracticeTag
                practice={getPracticeDetailsResults.data?.practices_by_pk}
              />
              Default Provider:{' '}
              {getPracticeDetailsResults.data?.practices_by_pk
                ?.default_provider && (
                <Tooltip
                  title={
                    getPracticeDetailsResults.data?.practices_by_pk
                      ?.default_provider?.provider_name
                  }
                >
                  <Typography.Text copyable>
                    {
                      getPracticeDetailsResults.data?.practices_by_pk
                        ?.default_provider?.provider_id
                    }
                  </Typography.Text>
                </Tooltip>
              )}
            </>
          )
        )}
      </Form.Item>
      <Form.Item
        label="Template Version"
        name="template_version"
        labelCol={{ span: 5 }}
        style={{ marginBottom: 4 }}
      >
        <Input />
      </Form.Item>
      <Divider dashed style={{ margin: 8 }}>
        Patient
      </Divider>
      <Form.Item
        label="Patient Name"
        name="beneficiary_name"
        labelCol={{ span: 5 }}
        style={{ marginBottom: 4 }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        noStyle
        dependencies={['date_assessment_complete', 'template_version']}
      >
        {({ getFieldValue }) => (
          <Form.Item
            label="Date Complete"
            name="date_assessment_complete"
            labelCol={{ span: 5 }}
            required={getFieldValue('template_version')?.startsWith(
              'HEDR-SDOH'
            )}
            validateStatus={
              // check that date_assessment_complete date is no more than three months ago with dayjs
              getFieldValue('date_assessment_complete') &&
              (dayjs(getFieldValue('date_assessment_complete')).isBefore(
                dayjs().subtract(3, 'month')
              ) ||
              dayjs()
                .add(1, 'day')
                .isBefore(getFieldValue('date_assessment_complete'))
                ? 'warning'
                : 'success')
            }
            help={
              getFieldValue('date_assessment_complete') &&
              (dayjs(getFieldValue('date_assessment_complete')).isBefore(
                dayjs().subtract(3, 'month')
              )
                ? 'Date is more than three months ago'
                : dayjs()
                    .add(1, 'day')
                    .isBefore(getFieldValue('date_assessment_complete'))
                ? 'Date is in the future'
                : undefined)
            }
          >
            <DatePicker format="M/D/YYYY" />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item noStyle dependencies={['mbi']}>
        {({ getFieldValue }) => (
          <Form.Item
            label="MBI"
            name="mbi"
            labelCol={{ span: 5 }}
            style={{ marginBottom: 8 }}
            hasFeedback={formInternal.getFieldValue('mbi')}
            dependencies={['mbi']}
            validateStatus={
              getFieldValue('mbi')
                ? getFieldValue('mbi').match(
                    /^[1-9][^a-zSLOIBZ\W_0-9][^a-zSLOIBZ\W_][0-9][^a-zSLOIBZ\W_0-9][^a-zSLOIBZ\W_][0-9][^a-zSLOIBZ\W_0-9]{2}[0-9]{2}$/
                  ) && getFieldValue('mbi') !== '1EG4TE5MK72'
                  ? 'success'
                  : 'error'
                : undefined
            }
            help={
              getFieldValue('mbi') &&
              !getFieldValue('mbi').match(
                /^[1-9][^a-zSLOIBZ\W_0-9][^a-zSLOIBZ\W_][0-9][^a-zSLOIBZ\W_0-9][^a-zSLOIBZ\W_][0-9][^a-zSLOIBZ\W_0-9]{2}[0-9]{2}$/
              )
                ? 'Invalid MBI Syntax'
                : getFieldValue('mbi') === '1EG4TE5MK72'
                ? '1EG4TE5MK72 is an example MBI, not a valid one'
                : undefined
            }
          >
            <Input
              allowClear
              onInput={(e) =>
                ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value
                  .replace(/[\W_]/, '')
                  .trim()
                  .toUpperCase())
              }
            />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item label="DOB" name="birth_date" labelCol={{ span: 5 }}>
        <DatePicker format="M/D/YYYY" />
      </Form.Item>
      <Form.Item label=" " labelCol={{ span: 5 }} colon={false}>
        <Dropdown
          trigger={['click']}
          overlay={
            uniquePatientsByHEDRDataResults?.length ? (
              <Menu
                onClick={({ key }) => {
                  if (
                    navigator.clipboard &&
                    uniquePatientsByHEDRDataResults.find(
                      (patient) => patient.patient_id === key
                    )?.mbi
                  ) {
                    navigator.clipboard.writeText(
                      uniquePatientsByHEDRDataResults.find(
                        (patient) => patient.patient_id === key
                      )?.mbi || ''
                    );
                  }
                }}
              >
                {uniquePatientsByHEDRDataResults.map((patient) => (
                  <Menu.Item key={patient.patient_id}>
                    <Tooltip title="Copy MBI to Clipboard">
                      <Typography.Text
                        mark={Boolean(
                          formInternal.getFieldValue('beneficiary_name') &&
                            patient.patient_name &&
                            formInternal
                              .getFieldValue('beneficiary_name')
                              .localeCompare(patient.patient_name, undefined, {
                                sensitivity: 'accent',
                              }) === 0
                        )}
                      >
                        {patient.patient_name}
                      </Typography.Text>
                      <span style={{ margin: '0 8px' }} />
                      <Typography.Text
                        type="secondary"
                        mark={formInternal.getFieldValue('mbi') === patient.mbi}
                      >
                        {patient.mbi}
                      </Typography.Text>
                      <span style={{ margin: '0 8px' }} />
                      <Typography.Text
                        type="secondary"
                        mark={
                          patient.birth_date &&
                          formInternal.getFieldValue('birth_date') &&
                          dayjs(patient.birth_date).format('M/D/YYYY') ===
                            formInternal
                              .getFieldValue('birth_date')
                              .format('M/D/YYYY')
                        }
                      >
                        {patient.birth_date &&
                          dayjs(patient.birth_date).format('M/D/YYYY')}
                      </Typography.Text>
                      <br />
                      <Typography.Text type="secondary">
                        {patient.practice?.practice_name}{' '}
                        {patient.practice?.practice_description &&
                          `: ${patient.practice?.practice_description}`}
                      </Typography.Text>
                    </Tooltip>
                  </Menu.Item>
                ))}
              </Menu>
            ) : (
              <Menu>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Menu>
            )
          }
        >
          <Button
            loading={searchPatientsByHEDRDataResults.loading}
            style={{ width: '100%' }}
          >
            <Space>
              Possible Patient Matches
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Form.Item>
      <Form.Item
        label="Declined?"
        name="assessment_declined"
        labelCol={{ span: 5 }}
        style={{ marginBottom: 4 }}
        colon={false}
      >
        <Select
          allowClear
          style={{ width: 80 }}
          options={[
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ]}
        />
      </Form.Item>
      <Divider dashed style={{ margin: 8 }}>
        Survey Values
      </Divider>
      <Tabs centered activeKey={selectedFormTab} onChange={setSelectedFormTab}>
        <Tabs.TabPane tab="Demographics" key="HEDR-DEMO">
          <Form.Item
            label="Preferred Language"
            name="preferred_language"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={LANGUAGES.map((l) => ({ value: l, label: l }))}
            />
          </Form.Item>
          <Form.Item
            label="Race"
            name="race"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                {
                  value: 'American Indian or Alaska Native',
                  label: 'American Indian or Alaska Native',
                },
                { value: 'Asian', label: 'Asian' },
                {
                  value: 'Black or African American',
                  label: 'Black or African American',
                },
                {
                  value: 'Native Hawaiian or Other Pacific Islander',
                  label: 'Native Hawaiian or Other Pacific Islander',
                },
                { value: 'White', label: 'White' },
                { value: 'Other', label: 'Other' },
                { value: 'Unknown', label: 'Unknown' },
                { value: 'Asked but unknown', label: 'Asked but unknown' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Ethnicity"
            name="ethnicity_base"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Hispanic or Latino', label: 'Hispanic or Latino' },
                {
                  value: 'Not Hispanic or Latino',
                  label: 'Not Hispanic or Latino',
                },
                { value: 'Other', label: 'Other' },
                { value: 'Unknown', label: 'Unknown' },
                { value: 'Asked but unknown', label: 'Asked but unknown' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Ethnicity Expanded 1"
            name="ethnicity_expanded_1"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={DETAILED_ETHNICITIES.map((l) => ({
                value: l,
                label: l,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Ethnicity Expanded 2"
            name="ethnicity_expanded_2"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={DETAILED_ETHNICITIES.map((l) => ({
                value: l,
                label: l,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Ethnicity Expanded 3"
            name="ethnicity_expanded_3"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={DETAILED_ETHNICITIES.map((l) => ({
                value: l,
                label: l,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Ethnicity Expanded 4"
            name="ethnicity_expanded_4"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={DETAILED_ETHNICITIES.map((l) => ({
                value: l,
                label: l,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Sexual Orientation"
            name="sex_orientation"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                {
                  value: 'Lesbian, gay, or homosexual',
                  label: 'Lesbian, gay, or homosexual',
                },
                {
                  value: 'Straight or heterosexual',
                  label: 'Straight or heterosexual',
                },
                { value: 'Bisexual', label: 'Bisexual' },
                {
                  value: 'Something else, please describe',
                  label: 'Something else, please describe',
                },
                { value: "Don't Know", label: "Don't Know" },
                {
                  value: 'Choose not to disclose',
                  label: 'Choose not to disclose',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Sexual Orientation Other"
            name="sex_orientation_os"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Gender Identity"
            name="gender_identity"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Male', label: 'Male' },
                { value: 'Female', label: 'Female' },
                {
                  value: 'Female-to-Male (FTM)/Transgender Male/Trans Man',
                  label: 'Female-to-Male (FTM)/Transgender Male/Trans Man',
                },
                {
                  value: 'Male-to-Female (MTF)/Transgender Female/Trans Woman',
                  label: 'Male-to-Female (MTF)/Transgender Female/Trans Woman',
                },
                {
                  value: 'Genderqueer, neither exclusively male nor female',
                  label: 'Genderqueer, neither exclusively male nor female',
                },
                {
                  value: 'Additional gender category or other, please specify',
                  label: 'Additional gender category or other, please specify',
                },
                {
                  value: 'Choose not to disclose',
                  label: 'Choose not to disclose',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Gender Identity Other"
            name="gender_identity_os"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Input />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="SDoH NC" key="HEDR-SDOH-NC">
          <Form.Item
            label="Question #1 (NC_Q1)"
            name="NC_Q1"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #2 (NC_Q2)"
            name="NC_Q2"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #3 (NC_Q4)"
            name="NC_Q4"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #4 (NC_Q5)"
            name="NC_Q5"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #5 (NC_Q6)"
            name="NC_Q6"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #6 (NC_Q7)"
            name="NC_Q7"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #7 (NC_Q8)"
            name="NC_Q8"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #8 (NC_Q9)"
            name="NC_Q9"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #9 (NC_Q10)"
            name="NC_Q10"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
            />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="SDoH AHC" key="HEDR-SDOH-AHC">
          <Form.Item
            label="Question #1 (AHC_71802_3)"
            name="AHC_71802_3"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                {
                  value: 'I have a steady place to live',
                  label: 'I have a steady place to live',
                },
                {
                  value:
                    'I have a place to live today, but I am worried about losing it in the future',
                  label:
                    'I have a place to live today, but I am worried about losing it in the future',
                },
                {
                  value:
                    'I do not have a steady place to live (I am temporarily staying with others, in a hotel, in a shelter, living outside on the street, on a beach, in a car, abandoned building, bus or train station, or in a park)',
                  label:
                    'I do not have a steady place to live (I am temporarily staying with others, in a hotel, in a shelter, living outside on the street, on a beach, in a car, abandoned building, bus or train station, or in a park)',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #2 Pests (AHC_LA31996_4)"
            name="AHC_LA31996_4"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #2 Mold (AHC_LA28580_1)"
            name="AHC_LA28580_1"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #2 Lead (AHC_LA31997_2)"
            name="AHC_LA31997_2"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #2 Heat (AHC_LA31998_0)"
            name="AHC_LA31998_0"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #2 Oven (AHC_LA31999_8)"
            name="AHC_LA31999_8"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #2 Smoke (AHC_LA32000_4)"
            name="AHC_LA32000_4"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #2 Water (AHC_LA32001_2)"
            name="AHC_LA32001_2"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #2 None (AHC_LA9_3)"
            name="AHC_LA9_3"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #3 (AHC_88122_7)"
            name="AHC_88122_7"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Often true', label: 'Often true' },
                { value: 'Sometimes true', label: 'Sometimes true' },
                { value: 'Never true', label: 'Never true' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #4 (AHC_88123_5)"
            name="AHC_88123_5"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Often true', label: 'Often true' },
                { value: 'Sometimes true', label: 'Sometimes true' },
                { value: 'Never true', label: 'Never true' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #5 (AHC_93030_5)"
            name="AHC_93030_5"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #6 (AHC_96779_4)"
            name="AHC_96779_4"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
                { value: 'Already shut off', label: 'Already shut off' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #7 (AHC_95618_5)"
            name="AHC_95618_5"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Never', label: 'Never' },
                { value: 'Rarely', label: 'Rarely' },
                { value: 'Sometimes', label: 'Sometimes' },
                { value: 'Fairly often', label: 'Fairly often' },
                { value: 'Frequently', label: 'Frequently' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #8 (AHC_95617_7)"
            name="AHC_95617_7"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Never', label: 'Never' },
                { value: 'Rarely', label: 'Rarely' },
                { value: 'Sometimes', label: 'Sometimes' },
                { value: 'Fairly often', label: 'Fairly often' },
                { value: 'Frequently', label: 'Frequently' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #9 (AHC_95616_9)"
            name="AHC_95616_9"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Never', label: 'Never' },
                { value: 'Rarely', label: 'Rarely' },
                { value: 'Sometimes', label: 'Sometimes' },
                { value: 'Fairly often', label: 'Fairly often' },
                { value: 'Frequently', label: 'Frequently' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #10 (AHC_95615_1)"
            name="AHC_95615_1"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Never', label: 'Never' },
                { value: 'Rarely', label: 'Rarely' },
                { value: 'Sometimes', label: 'Sometimes' },
                { value: 'Fairly often', label: 'Fairly often' },
                { value: 'Frequently', label: 'Frequently' },
              ]}
            />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="SDoH PRAPARE" key="HEDR-SDOH-PRA">
          <Form.Item
            label="Question #1 (P_93035_4)"
            name="P_93035_4"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
                {
                  value: 'I choose not to answer this question',
                  label: 'I choose not to answer this question',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #2 (P_93034_7)"
            name="P_93034_7"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
                {
                  value: 'I choose not to answer this question',
                  label: 'I choose not to answer this question',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #3 (P_63512_8)"
            name="P_63512_8"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item
            label="Question #3 Decline (P_LA30122_8_FAM)"
            name="P_LA30122_8_FAM"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #4 (P_71802_3)"
            name="P_71802_3"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'I have housing', label: 'I have housing' },
                {
                  value:
                    'I do not have housing (staying with others, in a hotel, in a shelter, living outside on the street, on a beach, in a car, or in a park',
                  label:
                    'I do not have housing (staying with others, in a hotel, in a shelter, living outside on the street, on a beach, in a car, or in a park',
                },
                {
                  value: 'I choose not to answer this question',
                  label: 'I choose not to answer this question',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #5 (P_93033_9)"
            name="P_93033_9"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
                {
                  value: 'I choose not to answer this question',
                  label: 'I choose not to answer this question',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #6 (P_82589_3)"
            name="P_82589_3"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                {
                  value: 'Less than high school degree',
                  label: 'Less than high school degree',
                },
                {
                  value: 'High school diploma or GED',
                  label: 'High school diploma or GED',
                },
                {
                  value: 'More than high school',
                  label: 'More than high school',
                },
                {
                  value: 'I choose not to answer this question',
                  label: 'I choose not to answer this question',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #7 (P_67875_5)"
            name="P_67875_5"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Unemployed', label: 'Unemployed' },
                {
                  value: 'Part-time or temporary work',
                  label: 'Part-time or temporary work',
                },
                { value: 'Full-time work', label: 'Full-time work' },
                {
                  value:
                    'Otherwise unemployed but not seeking work (ex: student, retired, disabled, unpaid primary care giver), Please write',
                  label:
                    'Otherwise unemployed but not seeking work (ex: student, retired, disabled, unpaid primary care giver), Please write',
                },
                {
                  value: 'I choose not to answer this question',
                  label: 'I choose not to answer this question',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #7 Other (P_67875_5_OS)"
            name="P_67875_5_OS"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="Question #8 (P_63586_2)"
            name="P_63586_2"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Question #8 Decline (P_LA30122_8_INC)"
            name="P_LA30122_8_INC"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #9 Food (P_LA30125_1)"
            name="P_LA30125_1"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #9 Clothing (P_LA30126_9)"
            name="P_LA30126_9"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #9 Utilities (P_LA30124_4)"
            name="P_LA30124_4"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #9 Child Care (P_LA30127_7)"
            name="P_LA30127_7"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #9 Medicine (P_LA30128_5)"
            name="P_LA30128_5"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #9 Phone (P_LA30129_3)"
            name="P_LA30129_3"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #9 Other (P_93031_3_O)"
            name="P_93031_3_O"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #9 Other text (P_93031_3_OS)"
            name="P_93031_3_OS"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label="Question #9 Decline (P_LA30122_8_NEEDS)"
            name="P_LA30122_8_NEEDS"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'TRUE', label: 'TRUE' },
                { value: 'FALSE', label: 'FALSE' },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #10 (P_93030_5)"
            name="P_93030_5"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                {
                  value:
                    'Yes, it has kept me from medical appointments or from getting my medications',
                  label:
                    'Yes, it has kept me from medical appointments or from getting my medications',
                },
                {
                  value:
                    'Yes, it has kept me from non-medical meetings, appointments, work, or from getting things that I need',
                  label:
                    'Yes, it has kept me from non-medical meetings, appointments, work, or from getting things that I need',
                },
                { value: 'No', label: 'No' },
                {
                  value: 'I choose not to answer this question',
                  label: 'I choose not to answer this question',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #11 (P_93029_7)"
            name="P_93029_7"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                {
                  value: 'Less than once a week',
                  label: 'Less than once a week',
                },
                { value: '1 or 2 times a week', label: '1 or 2 times a week' },
                { value: '3 to 5 times a week', label: '3 to 5 times a week' },
                {
                  value: '5 or more times a week',
                  label: '5 or more times a week',
                },
                {
                  value: 'I choose not to answer this question',
                  label: 'I choose not to answer this question',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #12 (P_93038_8)"
            name="P_93038_8"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Not at all', label: 'Not at all' },
                { value: 'A little bit', label: 'A little bit' },
                { value: 'Somewhat', label: 'Somewhat' },
                { value: 'Quite a bit', label: 'Quite a bit' },
                { value: 'Very much', label: 'Very much' },
                {
                  value: 'I choose not to answer this question',
                  label: 'I choose not to answer this question',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #13 (P_93026_3)"
            name="P_93026_3"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
                { value: 'Unsure', label: 'Unsure' },
                {
                  value: 'I choose not to answer this question',
                  label: 'I choose not to answer this question',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Question #14 (P_76501_6)"
            name="P_76501_6"
            labelCol={{ span: 10 }}
            style={{ marginBottom: 4 }}
          >
            <Select
              allowClear
              style={{ width: 250 }}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
                {
                  value: 'I have not had a partner in the past year',
                  label: 'I have not had a partner in the past year',
                },
                {
                  value: 'I choose not to answer this question',
                  label: 'I choose not to answer this question',
                },
              ]}
            />
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>

      <Divider dashed> </Divider>
      <Form.Item label="Notes" name="transcriber_notes" labelCol={{ span: 5 }}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="processing_status"
        labelCol={{ span: 5 }}
        label="Status"
        dependencies={[
          'mbi',
          'network_number',
          'template_version',
          'date_assessment_complete',
          'assessment_declined',
          'NC_Q1',
          'NC_Q2',
          'NC_Q4',
          'NC_Q5',
          'NC_Q6',
          'NC_Q7',
          'NC_Q8',
          'NC_Q9',
          'NC_Q10',
          'AHC_71802_3',
          'AHC_LA31996_4',
          'AHC_LA28580_1',
          'AHC_LA31997_2',
          'AHC_LA31998_0',
          'AHC_LA31999_8',
          'AHC_LA32000_4',
          'AHC_LA32001_2',
          'AHC_LA9_3',
          'AHC_88122_7',
          'AHC_88123_5',
          'AHC_93030_5',
          'AHC_96779_4',
          'AHC_95618_5',
          'AHC_95617_7',
          'AHC_95616_9',
          'AHC_95615_1',
          'P_93035_4',
          'P_93034_7',
          'P_63512_8',
          'P_LA30122_8_FAM',
          'P_71802_3',
          'P_93033_9',
          'P_82589_3',
          'P_67875_5',
          'P_67875_5_OS',
          'P_63586_2',
          'P_LA30122_8_INC',
          'P_LA30125_1',
          'P_LA30126_9',
          'P_LA30124_4',
          'P_LA30127_7',
          'P_LA30128_5',
          'P_LA30129_3',
          'P_93031_3_O',
          'P_93031_3_OS',
          'P_LA30122_8_NEEDS',
          'P_93030_5',
          'P_93029_7',
          'P_93038_8',
          'P_93026_3',
          'P_76501_6',
        ]}
        rules={[
          ({ getFieldValue, getFieldsValue }) => ({
            validator: (_, value) => {
              if (value === 'Completed') {
                // Check that network is present and valid
                if (!getFieldValue('network_number')) {
                  return Promise.reject('Network Number Required');
                }
                // Check that MBI is present and valid
                if (!getFieldValue('mbi')) {
                  return Promise.reject('MBI Required');
                }
                if (getFieldValue('mbi') === '1EG4TE5MK72') {
                  return Promise.reject(
                    '1EG4TE5MK72 is an example MBI, not a valid one'
                  );
                }
                if (
                  !getFieldValue('mbi').match(
                    /^[1-9][^a-zSLOIBZ\W_0-9][^a-zSLOIBZ\W_][0-9][^a-zSLOIBZ\W_0-9][^a-zSLOIBZ\W_][0-9][^a-zSLOIBZ\W_0-9]{2}[0-9]{2}$/
                  )
                ) {
                  return Promise.reject('Invalid MBI Syntax');
                }
                // Check that template_version starts with one of the known prefixes
                if (
                  ![
                    'HEDR-DEMO',
                    'HEDR-SDOH-NC',
                    'HEDR-SDOH-AHC',
                    'HEDR-SDOH-PRA',
                  ].some((prefix) =>
                    getFieldValue('template_version')?.startsWith(prefix)
                  )
                ) {
                  return Promise.reject('Invalid template_version');
                }
                // if template version starts with HEDR-SDOH, check that date_assessement_complete is filled
                if (
                  getFieldValue('template_version')?.startsWith('HEDR-SDOH') &&
                  !getFieldValue('date_assessment_complete')
                ) {
                  return Promise.reject(
                    'Date Assessment Complete is required for SDOH forms'
                  );
                }
                // if template version starts with HEDR-SDOH and assessment_declined is true,
                // check that the value of all fields starting with NC, AHC, and P is null or undefined
                if (
                  getFieldValue('template_version')?.startsWith('HEDR-SDOH') &&
                  getFieldValue('assessment_declined') === 'Yes'
                ) {
                  const fields = Object.keys(getFieldsValue(true)).filter(
                    (key) =>
                      key.startsWith('NC_') ||
                      key.startsWith('AHC_') ||
                      key.startsWith('P_')
                  );
                  const invalidFields = fields.filter(
                    (key) =>
                      getFieldValue(key) !== '' &&
                      getFieldValue(key) !== null &&
                      getFieldValue(key) !== undefined
                  );
                  if (invalidFields.length > 0) {
                    return Promise.reject(
                      'If assessment declined, all NC, AHC, and P fields must be null'
                    );
                  }
                }

                // IF AHC_LA9_3 is TRUE, check that AHC_LA31996 to AHC_LA32001 are all FALSE
                if (
                  getFieldValue('AHC_LA9_3') === 'TRUE' &&
                  ![
                    'AHC_LA31996_4',
                    'AHC_LA28580_1',
                    'AHC_LA31997_2',
                    'AHC_LA31998_0',
                    'AHC_LA31999_8',
                    'AHC_LA32000_4',
                    'AHC_LA32001_2',
                  ].every((key) => getFieldValue(key) === 'FALSE')
                ) {
                  return Promise.reject(
                    'If AHC_LA9_3 is TRUE, AHC_LA31996 to AHC_LA32001 must all be FALSE'
                  );
                }
                // IF AHC_LA9_3 is FALSE, check that all AHC_LA31996 to AHC_LA32001 are TRUE/FALSE
                // and at least one is TRUE
                if (
                  getFieldValue('AHC_LA9_3') === 'FALSE' &&
                  (![
                    'AHC_LA31996_4',
                    'AHC_LA28580_1',
                    'AHC_LA31997_2',
                    'AHC_LA31998_0',
                    'AHC_LA31999_8',
                    'AHC_LA32000_4',
                    'AHC_LA32001_2',
                  ].some((key) => getFieldValue(key) === 'TRUE') ||
                    ![
                      'AHC_LA31996_4',
                      'AHC_LA28580_1',
                      'AHC_LA31997_2',
                      'AHC_LA31998_0',
                      'AHC_LA31999_8',
                      'AHC_LA32000_4',
                      'AHC_LA32001_2',
                    ].every(
                      (key) =>
                        getFieldValue(key) === 'TRUE' ||
                        getFieldValue(key) === 'FALSE'
                    ))
                ) {
                  return Promise.reject(
                    'If AHC_LA9_3 is FALSE, AHC_LA31996 to AHC_LA32001 must all be TRUE/FALSE and at least one must be TRUE'
                  );
                }
                // IF AHC_LA9_3 is null, check that AHC_LA31996 to AHC_LA32001 are all null
                if (
                  !Boolean(getFieldValue('AHC_LA9_3')) &&
                  [
                    'AHC_LA31996_4',
                    'AHC_LA28580_1',
                    'AHC_LA31997_2',
                    'AHC_LA31998_0',
                    'AHC_LA31999_8',
                    'AHC_LA32000_4',
                    'AHC_LA32001_2',
                  ].some((key) => Boolean(getFieldValue(key)))
                ) {
                  return Promise.reject(
                    'If AHC_LA9_3 is null, AHC_LA31996 to AHC_LA32001 must all be null'
                  );
                }

                // P_63512_8 should be filled if and only if P_LA30122_8_FAM is FALSE
                if (
                  !getFieldValue('P_63512_8') &&
                  getFieldValue('P_LA30122_8_FAM') === 'FALSE'
                ) {
                  return Promise.reject(
                    'Check value for P_63512_8. If there is no value, P_LA30122_8_FAM must either be TRUE (declined) or blank (skipped)'
                  );
                }
                if (
                  getFieldValue('P_63512_8') &&
                  getFieldValue('P_LA30122_8_FAM') !== 'FALSE'
                ) {
                  return Promise.reject(
                    'A value for P_63512_8 is provided, so P_LA30122_8_FAM must be FALSE (not declined)'
                  );
                }

                // Similarly, P_63586_2 should be filled if and only if P_LA30122_8_INC is FALSE
                if (
                  !getFieldValue('P_63586_2') &&
                  getFieldValue('P_LA30122_8_INC') === 'FALSE'
                ) {
                  return Promise.reject(
                    'Check value for P_63586_2. If there is no value, P_LA30122_8_INC must either be TRUE (declined) or blank (skipped)'
                  );
                }
                if (
                  getFieldValue('P_63586_2') &&
                  getFieldValue('P_LA30122_8_INC') !== 'FALSE'
                ) {
                  return Promise.reject(
                    'A value for P_63586_2 is provided, so P_LA30122_8_INC must be FALSE (not declined)'
                  );
                }

                // If P_67875_5 is 'Otherwise unemployed but not seeking work', check that P_67875_5_OS is filled
                if (
                  getFieldValue('P_67875_5') ===
                    'Otherwise unemployed but not seeking work (ex: student, retired, disabled, unpaid primary care giver), Please write' &&
                  !getFieldValue('P_67875_5_OS')
                ) {
                  return Promise.reject(
                    'If P_67875_5 is "Otherwise unemployed...", P_67875_5_OS is required. If text not provided, leave P_67875_5 blank'
                  );
                }
                // Similarly, if P_67875_5_OS is filled, check that P_67875_5 is 'Otherwise unemployed but not seeking work'
                if (
                  getFieldValue('P_67875_5_OS') &&
                  getFieldValue('P_67875_5') !==
                    'Otherwise unemployed but not seeking work (ex: student, retired, disabled, unpaid primary care giver), Please write'
                ) {
                  return Promise.reject(
                    'If P_67875_5_OS is filled, P_67875_5 must be "Otherwise unemployed but not seeking work"'
                  );
                }
                // If P_93031_3_O is TRUE, check that P_93031_3_OS is filled
                if (
                  getFieldValue('P_93031_3_O') === 'TRUE' &&
                  !getFieldValue('P_93031_3_OS')
                ) {
                  return Promise.reject(
                    'If P_93031_3_O is TRUE, P_93031_3_OS is required'
                  );
                }
                // If P_93031_3_OS is filled, check that P_93031_3_O is TRUE
                if (
                  getFieldValue('P_93031_3_OS') &&
                  getFieldValue('P_93031_3_O') !== 'TRUE'
                ) {
                  return Promise.reject(
                    'If P_93031_3_OS is filled, P_93031_3_O must be TRUE'
                  );
                }
                // Check that P_93031_3_O is not "No", "None", or "N/A" (case-insensitive)
                if (
                  ['NO', 'NONE', 'N/A'].includes(
                    getFieldValue('P_93031_3_OS')?.toUpperCase()
                  )
                ) {
                  return Promise.reject(
                    'If P_93031_3_OS is "No", "None", "N/A", etc., clear it and mark P_93031_3_O as FALSE'
                  );
                }
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Radio.Group size="middle" buttonStyle="solid">
          <Radio.Button value="Unprocessed">
            <Badge status="default" />
            Unprocessed
          </Radio.Button>
          <Radio.Button value="Completed">
            <Badge status="success" />
            Completed
          </Radio.Button>
          <Dropdown
            placement="topLeft"
            overlay={
              <Menu
                onClick={({ key }) => {
                  formInternal.setFieldsValue({
                    processing_status: 'Invalid',
                    transcriber_notes: key,
                  });
                  if (setHaveValuesChanged) {
                    setHaveValuesChanged(
                      valuesHaveChanged(formInternal.getFieldsValue())
                    );
                  }
                }}
              >
                <Menu.ItemGroup title="Select and add note">
                  <Menu.Item key="Not a HEDR Form">Not a HEDR Form</Menu.Item>
                  <Menu.Item key="Neither declined nor answered">
                    Neither declined nor answered
                  </Menu.Item>
                  <Menu.Item key="Multiple forms">Multiple forms</Menu.Item>
                </Menu.ItemGroup>
              </Menu>
            }
          >
            <Radio.Button value="Invalid">
              <Badge status="error" />
              Invalid
            </Radio.Button>
          </Dropdown>
          <Dropdown
            placement="topLeft"
            overlay={
              <Menu
                onClick={({ key }) => {
                  formInternal.setFieldsValue({
                    processing_status: 'Awaiting Information',
                    transcriber_notes: key,
                  });
                  if (setHaveValuesChanged) {
                    setHaveValuesChanged(
                      valuesHaveChanged(formInternal.getFieldsValue())
                    );
                  }
                }}
              >
                <Menu.ItemGroup title="Select and add note">
                  <Menu.Item key="Missing/Invalid MBI">
                    Missing/Invalid MBI
                  </Menu.Item>
                  <Menu.Item key="Missing Assessment Date">
                    Missing Assessment Date
                  </Menu.Item>
                </Menu.ItemGroup>
              </Menu>
            }
          >
            <Radio.Button value="Awaiting Information">
              <Badge status="warning" />
              Awaiting Information
            </Radio.Button>
          </Dropdown>
          <Dropdown
            placement="topLeft"
            overlay={
              <Menu
                onClick={({ key }) => {
                  formInternal.setFieldsValue({
                    processing_status: 'Internal Review',
                    transcriber_notes: key,
                  });
                  if (setHaveValuesChanged) {
                    setHaveValuesChanged(
                      valuesHaveChanged(formInternal.getFieldsValue())
                    );
                  }
                }}
              >
                <Menu.ItemGroup title="Select and add note">
                  <Menu.Item key="Multiple forms; split required">
                    Multiple forms; split required
                  </Menu.Item>
                  <Menu.Item key="Survey Incomplete">
                    Survey Incomplete
                  </Menu.Item>
                </Menu.ItemGroup>
              </Menu>
            }
          >
            <Radio.Button value="Internal Review">
              <Badge status="warning" />
              Internal Review
            </Radio.Button>
          </Dropdown>
        </Radio.Group>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 5 }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={
            !haveValuesChanged ||
            updateHedrFormResults.loading ||
            getNetworkByNumberResults.loading ||
            searchPatientsByHEDRDataResults.loading
          }
          loading={updateHedrFormResults.loading}
          size="middle"
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export { HEDRForm };
